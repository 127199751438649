<template>
  <v-row
    class="paginator"
  >
    <v-divider />
    <v-col
      cols="6"
      style="margin: auto;"
    >
      <v-pagination
        :color="$store.state.color"
        prev-icon="irz-menu-left"
        next-icon="irz-menu-right"
        :model-value="propPage"
        :length="propPageCount"
        :total-visible="1"
        show-first-last-page
        @update:modelValue="onChangePage"
      >
        <template #item>
          <v-btn 
            variant="text" 
            class="paginator_btn m-0 p-0 rounded-circle" 
            color="none"
          >
            <div style="margin-top:10px;">
              <span style="font-size: 16px;">
                {{ propPage }}
              </span>
              <hr 
                class="m-0 p-0"
                style="margin-top: -4px !important; margin-bottom: -4px !important;" 
              >
              <span style="font-size: 10px;">
                {{ propPageCount }}
              </span>
            </div>
          </v-btn>
        </template>
      </v-pagination>
    </v-col>
    <v-spacer />
    <v-col
      cols="6"
      md="2"
      style="min-width: 180px"
    >
      <v-select
        :items="qty"
        :model-value="cookieItemsPerPage"
        :label="$t('Custom.Paginator.count')"
        hide-details
        variant="solo-filled"
        dense
        @update:modelValue="onChangeItemsPerPage"
      />
    </v-col>
  </v-row>
</template>

<script>



export default {
    props: {
        propPage: {
            type: Number,
            default: 1
        },
        propItemsPerPage: {
            type: Number,
            default: 10
        },
        propPageCount: {
            type: Number,
            default: 1
        },
        prefixCookiesName: {
          type: String,
          default: ""
        }
    },

    emits: ['update:propPage', 'update:propItemsPerPage'],

    data() {
      return {
        cookieItemsPerPage: undefined,
        cookiePage: undefined,
        qty: [
          {title: "10", value: 10},
          {title: "50", value: 50},
          {title: "100", value: 100},
        ],
      }
    },
    computed: {
      cookiesPrefix() {
        return this.prefixCookiesName || this.$route.name;
      }
    },
    mounted() {
      this.cookiePage = this.$cookies.getOrDefaultJSON(`${this.cookiesPrefix}-paginatorPage`, this.propPage);
      if (this.propItemsPerPage && !this.qty.map(x => x.value).includes(this.propItemsPerPage)) {
        this.qty.unshift({title: this.propItemsPerPage.toString(), value: this.propItemsPerPage});
        this.cookieItemsPerPage = this.propItemsPerPage;
      } else {
        this.cookieItemsPerPage = this.$cookies.getOrDefaultJSON(`${this.cookiesPrefix}-paginatorItemsPerPage`, this.propItemsPerPage);
      }
      this.onChangePage(this.cookiePage);
      this.onChangeItemsPerPage(this.cookieItemsPerPage);
    }, 
    
    methods: {
      onChangePage(event) {
        this.$emit('update:propPage', event)
        this.$cookies.setJSON(`${this.cookiesPrefix}-paginatorPage`, event);
        this.cookiePage = event;
      },

      onChangeItemsPerPage(event) {
        this.$emit('update:propItemsPerPage', event)
        this.$cookies.setJSON(`${this.cookiesPrefix}-paginatorItemsPerPage`, event);
        this.cookieItemsPerPage = event;
      },
    },
}
</script>

<style>
.paginator_btn{
  min-width: 50px;
  height: 50px !important;
}
</style>