import { createApp } from 'vue';
import "bootstrap";

// Components
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueClipboard from 'vue3-clipboard';
import requireComponent from "./components";

// Plugins
import {chartist, CodeMirror, time, vuetify, ws, merge, exportCookies, files, accounting, basicTypes} from './plugins'

// Directives
import DOMPurify from "dompurify";

import App from './App'

import { router } from '@/router'
import {store} from './store'

import {i18n} from "./i18n";

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

window.BREAK_POINT = 1281;

const app = createApp(App);

app.use(router)
// app.use(Meta)
app.use(store)
app.use(i18n)
app.use(chartist)
app.use(CodeMirror)
app.use(time)
app.use(vuetify)
app.use(exportCookies)
app.use(files)
app.use(accounting)
app.use(ws)
app.use(merge)
app.use(basicTypes)

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})

app.component('VueDatePicker', VueDatePicker);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
      camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
  )

  app.component(componentName, componentConfig.default || componentConfig)
})

app.directive("sane-html", (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value);
});


app.mount('#app')
