const basicTypes = [
  {type: "shell"},
  {type: "reboot"},
  {type: "factory_reset"},
  {type: "download"},
  {type: "pkg_install"},
  {type: "fw_update"},
  {type: "task_log"},
  {type: "task_backup"},
]

export {basicTypes}