<template>
  <div class="input-group">
    <v-text-field
      ref="hiddenField"
      v-model.trim="model"
      :variant="variant.split('--')[0]"
      :type="visible ? 'text' : 'password'"
      :label="label"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      :rules="required_and_rules"
      :readonly="readonly"
      :color="color"
      :hide-details="rules?.length ? 'auto' : true"
      @keyup.enter="enter"
    >
      <template
        v-for="name in Object.keys($slots)"
        :key="name"
        #[`${name}`]="{ item }"
      >
        <slot
          :name="name"
          :item="item"
        />
      </template>

      <template
        v-if="is('tooltip') && !$slots.hasOwnProperty('append-inner')"
        #append-inner
      >
        <custom-tooltip
          v-model:active-tooltip-id="activeTooltip"
          variant="append-inner"
          type="normal"
          id-tooltip="info_pass_tooltip"
        >
          <template #text>
            <div v-if="requiredLength">
              <ul>
                <li>
                  {{
                    $t('Common.Rules.rule_min_length')
                      .replace("#1",
                               requiredLength
                      )
                  }}
                </li>
                <li>
                  {{ $t('Common.Rules.pass_rule_digits') }}
                </li>
                <li>
                  {{ $t('Common.Rules.pass_rule_alpha_small') }}
                </li>
                <li>
                  {{ $t('Common.Rules.pass_rule_alpha_large') }}
                </li>
                <li>
                  {{ $t('Common.Rules.spec_symbol') + " %, *, ), ?, @, #, $, ~" }}
                </li>
              </ul>
            </div>
            <div v-else>
              <p>
                {{ $t('Common.Rules.rule_min_length').replace("#1", 8) }}
              </p>
            </div>
          </template>
        </custom-tooltip>
      </template>

      <template
        v-else-if="!$slots.hasOwnProperty('append')"
        #append-inner
      >
        <v-icon
          @click="visible = !visible"
        >
          {{ visible ? 'irz-eye' : 'irz-eye-off' }}
        </v-icon>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import {required as req} from "@/plugins/fieldRules";

export default {
    props: {
        /** The value of the input */
        modelValue: {
            type: String,
            default: ""
        },
        /** A variant of field */
        variant: {
            type: String,
            default: "flat"
        },
        /** A placeholder to use */
        placeholder: {
            type: String,
            default: ""
        },
        /** Maximum length of the input */
        maxlength: {
            type: Number,
            default: 255
        },
        /** Should the field auto complete */
        autocomplete: {
            type: String,
            default: "off",
        },
        /** Is the input required? */
        required: {
            type: Boolean,
            default: true
        },
        /** List of rules for validate input */
        rules: {
            type: Array,
            default() {
              return [];
            }
        },
        /** Min required input length */
        requiredLength: {
            type: Number,
            default: 0
        },
        /** Should the input be read only? */
        readonly: {
            type: Boolean,
            default: undefined,
        },
        label: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: 'inherit'
        },
        enter: {
            type: Function,
            default: (() => {})
        },
        visibility: {
            type: Boolean,
            default: false
        },
        activeTooltipId: {
            type: String,
            default: ""
        },
    },

    emits: [ "update:modelValue", "update:activeTooltipId", "update:visibility" ],

    computed: {
        model: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        },

        activeTooltip: {
            get() {
                return this.activeTooltipId;
            },
            set(value) {
                this.$emit("update:activeTooltipId", value);
            }
        },

        visible: {
            get() {
                return this.visibility;
            },
            set(value) {
                this.$emit("update:visibility", value);
            }
        },

        required_and_rules() {
            return (this.required ? [req] : []).concat(this.rules);
        }
    },

    methods: {
        req,

        shareFocus() {
            this.$refs.hiddenField.focus();
        },

        is(k) {
            return this.variant && this.variant.split('--').includes(k);
        },
    }
};
</script>
