import { i18n } from '@/i18n'

export const required = v => !!v && String(v).trim().length !=0 ;

export const matchRule = (pattern, msg=i18n.global.t('Common.Rules.mail_rule_2'), empty=false) =>
    ((v) => empty && !v || new RegExp(pattern).test(v) || msg)

export const fieldRulesList = {
    usernameRules: [
        required,
        v => !!v && String(v).trim().length > 3  || i18n.global.t('Common.Rules.rule_min_length').replace('#1', 4),
    ],
    textFieldRules: [
        v => !!v && String(v).trim().length > 0  || i18n.global.t('Common.Rules.rule_min_length').replace('#1', 1),
    ],
    phoneRules: [
        v => String(v).length <= 24 || i18n.global.t('Common.Rules.phone_rule_1'),
        matchRule(/^(\+)?([-()]?[0-9][-()]?){3,}$/, '<+79112223344>, <+7(911)222-33-44>, <8(800)555-3-555>', true)
    ],
    emailRules: [
        matchRule(/^[A-Za-z\-_.0-9]+@[A-Za-z\-_0-9]{2,}(\.[a-z]{2,})+$/, '<example@link.net>, <ex_example_1@link.net>', true)
    ],
    tagRules: [
        v => !v || String(v).length >= 3 || i18n.global.t('Common.Rules.rule_min_length').replace('#1', '3'),
    ],
    tinRules: [
        v => !v || parseInt(v) >= 1e8 || i18n.global.t('Common.Rules.tin_rule')
    ],
    passwordLowRules: [
        required,
        v => String(v).length >= 8 || i18n.global.t('Common.Rules.rule_min_length').replace('#1', 8),
    ],
    passwordHighRules: [
        required,
        matchRule(/^(.*[0-9].*)$/, i18n.global.t('Common.Rules.pass_rule_digits')),
        matchRule(/^(.*[a-z].*)$/, i18n.global.t('Common.Rules.pass_rule_alpha_small')),
        matchRule(/^(.*[A-Z].*)$/, i18n.global.t('Common.Rules.pass_rule_alpha_large')),
        matchRule(/^(.*[$&+,:;=?@#|_\/'<>.\-^*()\[\]%!].*)$/, i18n.global.t('Common.Rules.spec_symbol')),
    ],
    invalidNums: [
        v => String(v).length > 0 || '',
        v => Math.round(parseInt(v)) === parseInt(v) || i18n.global.t('Common.Rules.int_only'),
        v => parseInt(v) >= 2 || (i18n.global.t('Common.Rules.non_less') + ' 2'),
        v => parseInt(v) <= 100000 || (i18n.global.t('Common.Rules.non_more') + ' 100000'),
    ],
    invalidPorts: [
        v => String(v).length > 0 || '',
        v => Math.round(parseInt(v)) === parseInt(v) || i18n.global.t('Common.Rules.int_only'),
        v => parseInt(v) >= 0 || (i18n.global.t('Common.Rules.non_less') + ' 0'),
        v => parseInt(v) <= 65535 || (i18n.global.t('Common.Rules.non_more') + ' 65535'),
    ],
    passwordConfirmRules(check) {
        return [v => v === check || i18n.global.t('Common.Rules.password_not_matched')]
    },
    isRepeating(check, length, isCombination) {
        return check.match('.*(.' + (isCombination ? '{' + length + ',}' : '') + ')' +
            (isCombination ? '.*' : '') + '\\1' + (isCombination ? '' : '{' + length + ',}') + '.*')
    },
    AESRules: [
        matchRule(/^([a-fA-F0-9]{0}|[a-fA-F0-9]{64})$/, i18n.global.t('Common.Rules.key_rule'), true),
    ],
}
