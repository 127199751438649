<template>
  <div
    :class="`tooltip_container tooltip_container--${newActiveTooltipId === idTooltip ? 'visible' : 'hidden'}`"
  >
    <v-icon
      icon
      @click="toggleTooltip"
    >
      irz-information-outline
    </v-icon>
    <div
      :class="`tooltip_control tooltip_control--${windowWidth >= 1050 ? 'right' : 'bottom'}`"
    >
      <div
        class="tooltip_arrow"
      >
        <div>
          <v-icon
            v-if="newActiveTooltipId === idTooltip"
            style="color: #0052cc;"
          >
            irz-arrow-tooltip
          </v-icon>
        </div>
      </div>
  
      <div
        :class="`tooltip_cloud tooltip_cloud--${type}`"
      >
        <div
          v-if="newActiveTooltipId === idTooltip"
          class="tooltip_cloud_text"
        >
          <div
            class="d-flex flex-column"
          >
            <slot name="text" />
          </div>
          <v-btn
            style="max-width: 20px; max-height: 20px;"
            icon="irz-close-tooltip"
            @click="closeTooltip"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default{
        props: {
            type: {
                type: String,
                required: true,
            },
            variant: {
                type: String,
                required: true,
            },
            idTooltip: {
                type: String,
                required: true
            },
            activeTooltipId: {
                type: String,
                default: ""
            }
        },

        emits: ['update:activeTooltipId'],

        data() {
            return {
                newActiveTooltipId: this.activeTooltipId,
                windowWidth: 900,
            }
        },

        watch: {
            activeTooltipId() {
                this.newActiveTooltipId = this.activeTooltipId;
            }
        },

        mounted() {
            this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width;
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            });
        },

        unmounted() {
            window.removeEventListener('resize', this.onResize);
        },

        methods: {
            closeTooltip() {
                this.newActiveTooltipId = "";
                this.$emit('update:activeTooltipId', this.newActiveTooltipId);
            },

            toggleTooltip(e) {
                e.preventDefault();

                if (this.newActiveTooltipId === this.idTooltip) {
                    this.closeTooltip();
                } else {
                    this.newActiveTooltipId = this.idTooltip;
                    this.$emit('update:activeTooltipId', this.newActiveTooltipId);
                }
            },

            onResize() {
                this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width
            },
        }
    }
</script>

<style scoped>
h2 {
    margin-top: 0;
}
.custom-white-box {
    height: 56px;
    width: 60px;
    background-color: white;
    position: absolute;
    top: -15px;
    right: 0px;
    z-index: -1;
    border-radius: 4px;
}
</style>