import { createVuetify } from 'vuetify';
import theme from './theme';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'; 
import '@/styles/material-icons-vue/scss/icons.scss'; 
 
const vuetify = createVuetify({ 
  iconfont: ['mdi'],

  theme: {
    defaultTheme: 'light',
    themes: {
      light: theme
    }
  },
})

export { vuetify };
