<template>
  <v-col
    cols="auto"
    class="v-card v-card--variant-elevated p-0 mr-1"
  >
    <!-- icon="irz-calendar-minus" -->
    <v-btn
      variant="text"
      class="action-button-mini v-btn--icon"
      @click="toggleCalendar"
    >
      <v-icon
        size="24"
        :color="calActive ? '#4F6281' : ''"
      >
        mdi-calendar-minus
      </v-icon>
      <v-tooltip  
        activator="parent"
        location="bottom"
      >
        {{ $t('Common.Tooltip.calendar') }}
      </v-tooltip>
    </v-btn>
  </v-col>

  <v-tabs
    v-if="calActive"
    v-model="calActiveTab"
    hide-slider
    center-active
    show-arrows
    style="h-40"
    class="mr-3 h-40"
    @update:model-value="updateCalActiveTab"
  >
    <v-tab
      v-for="tab_i in [0, 1, 2]"
      :key="tab_i"
      v-model="time"
      :style="getStyleButtonBR(tab_i)"
      class="bordered-tab h-40"
      @click="time = tab_i"
    >
      <span>{{ [$t('Events.List.today'), $t('Events.List.yesterday'), $t('Events.List.week')][tab_i] }}</span>
    </v-tab>

    <v-tab
      key="custom"
      v-model="time"
      :class="['ml-3', 'bordered-tab', 'calendar-tab', 'h-40', (windowWidth >= 1500 ? 'p-0' : 'mini-tab')]"
      @click="time='custom'"
    >
      <VueDatePicker
        ref="picker"
        v-model="calDate"
        :format-locale="dp_locales[$i18n.locale]"
        :locale="$i18n.locale"
        :format="calFormat"
        :preview-format="'d.MM.y'"
        :title="$t('Commands.New.input_date')"
        :dark="$store.state.isDarkTheme"
        range
        :multi-calendars="windowWidth >= 1200"
        :input-class-name="windowWidth >= 1500 ? 'w-250' : 'w-0'"
        :menu-class-name="'custom-calendar ' + (windowWidth >= 1500 ? '' : 'mt-3')"
        calendar-class-name="px-3"
        month-name-format="long"
        :offset="12"
        :enable-time-picker="false"
        :teleport="true"
        :clearable="false"
        :max-date="new Date()"
        @update:model-value="chooseCustomTab()"
      >
        <template #input-icon>
          <v-icon
            :class="(windowWidth >= 1500 ? 'input-slot-image' : 'mini-input-slot-image')"
          >
            irz-calendar-outline
          </v-icon>
        </template>
        <template #action-preview="{ value }">
          <div
            v-if="windowWidth >= 1200"
            class="v-card-actions px-4 align-items-center"
          >
            <v-label
              class="v-btn--variant-outlined font-weight-medium calendar-label"
            >
              {{ format(value[0]) }}
            </v-label>
            <span
              v-if="value[1]"
              class="mx-2"
            >—</span>
            <v-label
              v-if="value[1]"
              class="v-btn--variant-outlined font-weight-medium calendar-label"
            >
              {{ format(value[1]) }}
            </v-label>
          </div>
        </template>
        <template #action-buttons>
          <v-btn
            class="mr-2"
            variant="outlined"
            @click="$refs.picker.closeMenu"
          >
            {{ $t('Common.Data.cancel') }}
          </v-btn>
          <v-btn
            :color="$store.state.color"
            variant="flat"
            :disable="!$refs.picker.value"
            @click="$refs.picker.selectDate"
          >
            {{ $t('Common.Data.select') }}
          </v-btn>
        </template>
      </VueDatePicker>
    </v-tab>
  </v-tabs>
</template>

<script>
import {enUS, ru} from 'date-fns/locale';
import {format} from 'date-fns';

export default{
    props: {
        date : {
            type: Object,
            required: true
        },
        reloadElementsByDate: {
            type: Function,
            required: true
        },
        activeTab: {
            type: Number,
            required: true
        },
        active: {
            type: Boolean,
            required: true
        },
        selected: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    emits: ['update:activeTab', 'update:active', 'update:date', 'update:selected'],

    data() {
        return {
            pageLoadedAt: new Date(),
            calDate: this.date,
            dp_locales: {'en': enUS, 'ru': ru},

            calActive: this.active, 
            time: 0,
            calSelected: this.selected,
            calActiveTab: this.activeTab,

            windowWidth: 900,

            localeToggler: false,

            calFormat: 'd MMM y' + (this.localeToggler ? ' ' : ''),
        }
    }, 

    computed: {
      calLocale() {
        return this.$i18n.locale;
      },
    },

    watch: {
      // add reactivity for locale changing in input
      calLocale() {
        this.localeToggler = !this.localeToggler;
        this.calFormat = 'd MMM y' + (this.localeToggler ? ' ' : '');
      }

    },

    mounted() {
        this.windowWidth = window.innerWidth;
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })

    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize)
    },

    methods: {
        chooseCustomTab() {
            this.calSelected.length = 0;
            this.calActiveTab = 3;
            this.changeElements();
            this.updateCalDate();
            this.updateCalActiveTab();
            this.updateSelected();
        },

        changeElements(date, offset) {
            let d = date ? date : this.calDate;
            if (!d instanceof Array) {
                this.calDate = [d, d];
            } else if (d.length === 2 && d[0] !== null && d[1] !== null) {
                this.calDate = d;
            } else if (d.length >= 1 && d[0] !== null) {
                this.calDate = [d[0], d[0]];
            }

            if (!offset) {
                offset = 0;
                this.pageLoadedAt = new Date();
            }

            this.calDate[0] = this.$midnight(this.calDate[0]);
            this.calDate[1] = this.$midnight(this.$tomorrow(this.calDate[1]));
            this.calDate[1] = new Date(Math.min(this.pageLoadedAt.getTime(), this.calDate[1].getTime() - 1000));

            this.reloadElementsByDate(
                                    this.$dateDiff(new Date(), this.calDate[0]),
                                    this.$dateDiff(new Date(), this.calDate[1]),
                                    offset);
        },

        getStyleButtonBR(tab_i) {
          switch(tab_i) {
            case 0:
              return 'border-radius: 8px 0 0 8px'
            case 2:
              return 'border-radius: 0 8px 8px 0'
          }
        },

        onResize() {
            this.windowWidth = window.innerWidth;
        },

        updateSelected() {
            this.$emit('update:selected', this.calSelected);
        },

        updateCalDate() {
            this.$emit('update:date', this.calDate);
        },

        updateCalActiveTab() {
            this.$emit('update:activeTab', this.calActiveTab);
        },

        updateActive() {
            this.$emit('update:active', this.calActive);
        },

        format(date) {
            return format(date, 'd.MM.y');
        },

        toggleCalendar() {
            this.calSelected.length = 0;
            this.calActive = !this.calActive;
            this.calActiveTab = 0;
            this.updateCalActiveTab();
            this.updateActive();
            this.updateSelected();
        },

    }
}
</script>

<style>
.input-slot-image {
  height: 20px;
  width: auto;
  margin-left: 5px;
}

.mini-input-slot-image {
  height: 20px;
  left: -20px;
  width: auto;
  margin-left: 5px;
}

.bordered-tab {
  border: #BFC5CF solid 1px;
}

.bordered-tab.v-tab--selected {
  background: #73A2FE;
}

.bordered-tab.calendar-tab {
  align-content: center;
  border-radius: 8px !important;
  padding: 5px;
}

.bordered-tab.calendar-tab.mini-tab {
  display: block;
  width: 50px !important;
  min-width: 50px !important;
}

.dp__theme_light, 
.dp__theme_dark {
  --dp-hover-color: #73a2fe30 !important;
  --dp-border-color: transparent !important;
  --dp-border-color-hover: transparent !important;
  --dp-icon-color: #2D3748 !important;
  --dp-font-family: $font-family-normal;
}

.v-tab .dp__theme_light,
.v-tab .dp__theme_dark {
  --dp-background-color: transparent !important;
}

.w-0 {
    display: none;
}

.w-250 {
  width: 250px !important;
}

.calendar-label {
  border-radius: 5px; 
  padding: 5px 10px; 
  font-size: 14px
}
</style>
