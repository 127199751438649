<template>
  <helper-table
    v-model="newSelected"
    :variant="variant"
    :headers="headers"
    :show-select="showSelect"
    :sort-by="sortBy"
    :items-per-page="itemsPerPage"
    :prefix-cookies-name="prefixCookiesName"
    :items="files"
    item-value="_id"
    @update:model-value="updateSelect"
  >
    <template
      v-for="(_, name) in $slots"
      :key="name"
      #[`${name}`]="{ item }"
    >
      <slot
        :name="name"
        :item="item"
      />
    </template>

    <template #[`item.actions`]="{ item }">
      <span
        style="display: flex; justify-content: flex-end; align-items: center;"
      >
        <v-btn 
          variant="text" 
          style="font-size: 24px"
          class="v-btn--icon"
          @click="downloadData(item._id, item.filename)"
        >
          <v-icon>
            irz-download
          </v-icon>
          <v-tooltip  
            activator="parent"
            location="bottom"
            open-delay="500"
          >
            {{ $t('Common.Tooltip.download_one') }}
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="isAdmin"
          variant="text"
          class="v-btn--icon"
          :color="confirmDelete === item._id ? 'red' : ''"
          @click="deleteData(item._id)"
        >
          <v-icon>
            irz-delete
          </v-icon>
          <v-tooltip  
            v-if="confirmDelete !== item._id"
            activator="parent"
            location="bottom"
            open-delay="500"
          >
            {{ $t('Common.Tooltip.delete') }}
          </v-tooltip>
          <v-tooltip  
            v-else
            model-value="1"
            activator="parent"
            location="bottom"
          >
            {{ $t('Common.Tooltip.delete_click') }}
          </v-tooltip>
        </v-btn>
      </span>
    </template>
  </helper-table>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex"
import {files} from '@/plugins/files.js'

    export default {
        props: {
            // prop for custom row height
            variant: {
                type: String,
                default: 'comfortable',
            },
            countFiles: {
                type: Number,
                required: false,
                default: 0
            },
            conf: {
                type: Object,
                required: false,
                default() {
                    return {}
                }
            },
            typeTable: {
                type: String,
                required: true
            },
            search: {
                type: Object,
                required: false,
                default() {
                    return {
                        tags: new Set(),
                        filterTags: new Set(),
                        filterBehaviour: 'and',
                        filterIsNot: false
                    }
                }
            },
            modelValue: {
                type: Array,
                required: false,
                default() {
                    return [];
                }
            },
            headers: {
                type: Array,
                required: true
            },
            sortBy: {
                type: Array,
                required: false,
                default() {
                    return []
                }
            },
            showSelect: {
                type: Boolean,
            },
            itemsPerPage: {
                type: Number,
                default: 10
            },
            prefixCookiesName: {
                type: String,
                required: false,
                default: "",
            },
        },
        emits: ['update:modelValue', 'update:search', 'update:countFiles'],
        data() {
            return {
                newSearch: this.search,
                newSelected: this.modelValue,
                confirmDelete: false,
            }
        },
        computed: {
            ...mapState(['commons', 'logs', 'backups', 'command']),
            ...mapGetters(['isAdmin']),

            unCommonTags() {
                return ['log', 'backup']
            },

            commonTags() {
                return ['file', 'package', 'firmware']
            },

            files() {
                if (this.typeTable === 'command') {
                    return this.command.reports
                       .filter(r => r.file_status === 2 || r.attempts)
                       .map(r => r.file)
                       .filter(x => x);
                }
                const fs = [].concat(this[this.typeTable])
                    .map(x => {
                        x.size = this.bytesToSize(x.length);
                        x.create_date = this.$getDate(x.uploadDate);
                        return x;
                    });
                const and = this.search.filterBehaviour === 'and';
                const isNot = this.search.filterIsNot;
                let filtFs = this.typeTable === 'commons' ? fs
                    .filter(x => this.commonTags.includes(x.file_type)) : fs
                    .filter(x => this.unCommonTags.includes(x.file_type))
                const filterFiles = filtFs
                    .filter(x => {
                        return x && [].concat(...this.search.filterTags)
                            .map(tag => !tag || (tag.startsWith("@") ? tag.includes(x.file_type) : x.filename.includes(tag)))
                            .reduce((a, b) => and ? a && b : a || b, and)
                    });

                filtFs = isNot ? _.difference(filtFs, filterFiles) : filtFs;
                this.$emit("update:countFiles", filtFs.length);
                return filtFs;
            },
        },
        watch: {
            files(value) {
                this.newSearch.tags = new Set(
                    [... value.map(x => '@' + x.file_type),
                    ... value.map(x => x.filename)]
                );
                this.$emit("update:search", this.newSearch);
                this.$emit("update:countFiles", this.files.length);
            }
        },
        beforeMount() {
            if (this.typeTable === 'command') {
                this.getCommand(this.conf);
            } else {
                this.getCommonFiles(this.conf);
            }
        },
        methods: {
            ...mapActions(['getCommonFiles', 'getCommand']),

            updateSelect() {
                this.$emit("update:modelValue", this.newSelected);
            },

            updateCountFiles() {
                this.$emit("update:countFiles", this.files.length);
            },

            downloadData(file_id, filename) {
                this.$store.state.isLoading = true;
                files.downloadFile(file_id, filename)
                    .finally(() => {
                        this.$store.state.isLoading = false;
                    });
            },

            deleteData(file_id) {
                if (this.confirmDelete !== file_id) {
                    this.confirmDelete = file_id;
                    setTimeout(() => {
                        this.confirmDelete = false
                    }, 2000);
                    return
                }
                let setString = '';
                switch (this.typeTable) {
                                case 'commons':
                                    setString = 'setCommons';   
                                    break;
                                case 'logs':
                                    setString = 'setLogs';
                                    break;
                                case 'backups':
                                    setString = 'setBackups';
                                    break;
                                case 'command':
                                    setString = 'setCommand';
                }
                
                if ( this.typeTable === 'command') {
                    let newCommand = {}
                    for (let key in this.command) {
                        if(key === 'reports') {
                            newCommand[key] = this.command.reports.filter(f => f._id !== file_id);
                        } else {
                            newCommand[key] = this.command[key];
                        }
                    }
                    this.$store.commit(setString, newCommand);
                    files.deleteFile(file_id)
                        .then(() => {
                            setTimeout(() => {
                                this.getCommand(this.conf);
                        }, 1500);
                        })
                    return ;
                }
                this.$store.commit(setString, this[this.typeTable].filter(f => f._id !== file_id));
                files.deleteFile(file_id)
                        .then(() => {
                            setTimeout(() => {
                                this.getCommonFiles(this.conf);
                        }, 1500);
                        })
            },

            bytesToSize(bytes) {
                let sizes = this.$tm('Scripts.List.sizes');
                if (bytes === 0) return "0 " + sizes[0];
                let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
                return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
            },

        }
    }
</script>