<template>
  <v-container :fluid="true">
    <v-row
      justify="center"
      wrap
    >
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          variant="header--search--action-add"
          :title="$t('Notifications.Rules.rules')"
          :text="cardText"
          :search="search"
          @update:search="search=$event;"
          @add="openRule('new')"
        >
          <helper-table
            :headers="headers"
            :items="rules"
            :search="search"
            :prefix-cookies-name="prefixCookiesName"
          >
            <template #item="{ item }">
              <tr>
                <td class="notifications-checkbox">
                  <v-switch
                    v-model="item.active"
                    color="#0052cc"
                    density="compact"
                    hide-details
                    @click="activateRule(item._id, item.active)"
                  />
                </td>
                <td>
                  <span
                    class="clickable"
                    @click="openRule(item._id)"
                  >
                    {{ item.name }}
                  </span>
                </td>
                <td>{{ $t('Notifications.Monitors.' + item.monitor.name) }}</td>
                <td>{{ item.transport.name }}</td>
                <!--                                <td>{{ $getDate(item.create_time) }}</td>-->
              </tr>
            </template>
          </helper-table>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import {fetchData} from "../../plugins/fetchData";

export default {
    data() {
        return {
            name: "rules",
            prefixCookiesName: "Notifications",
            search: '',

            pagination: {
                page: 1,
                itemsPerPage: 10
            },

            windowWidth: 900,
            searchActive: false,
        };
    },

    computed: {
        rules() {
            return this.$store.getters.notificationRules || [];
        },

        cardText() {
            return this.$t('Notifications.Rules.all') + ": " + this.rules.length + ", " +
                   this.$t('Notifications.Rules.active') + ": " + this.rules.filter(x => x && x.active).length;
        },

        headers() {
            return [
                {title: '', sortable: false, align: "left", key: "active"},
                {title: this.$t('Notifications.Rules.name'), sortable: true, align: "left", key: "name"},
                {title: this.$t('Notifications.Rules.monitor'), sortable: false, align: "left", key: "monitor"},
                {title: this.$t('Notifications.Rules.transport'), sortable: false, key: "transport"},
                // {title: this.$t('Notifications.Rules.create_date'), sortable: true, key: "create_date"},
            ]
        },
    },

    mounted() {
        this.$store.dispatch('getNotificationRules');

        this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width;
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {

        onResize() {
            this.windowHeight = window.innerHeight
            this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width
        },

        openRule(rule_id) {
            this.$router.push({name: "notification-rule", params: {rule_id: rule_id}});
        },

        activateRule(rule_id, active) {
            fetchData("patch", "/api/notifications/rule/" + rule_id, {active: !active})
                .then(() => {
                    this.$store.dispatch('addInfoMessage', this.$t('Custom.Push.updated'))
                });
        },

        searchActivate() {
            this.filterTags = new Set();
            this.searchActive = !this.searchActive;
            setTimeout(() => { this.$refs.search.focus() }, 100)
        },
    }
};
</script>

<style scoped>
.notifications-checkbox {
  padding-right: 30px !important;
  padding-left: 10px !important;
	width: 3%;
}
</style>