<template>
  <v-toolbar
    id="core-toolbar"
    density="comfortable"
    :class="{'v-toolbar--hidden': hideToolbar}"
  >
    <v-app-bar-nav-icon
      v-if="responsive"
      class="ml-6 default v-btn--simple"
      theme="dark"
      @click.stop="onClickBtn"
    >
      <v-icon>irz-format-list-checks</v-icon>
    </v-app-bar-nav-icon>

    <v-btn
      v-if="backRoute"
      icon="irz-arrow-left"
      :style="responsive ? {} : {'margin-left': '315px'}"
      @click="exit"
    />

    <v-spacer />
    <v-toolbar-items>
      <v-menu
        bottom
        left
        content-class=""
        offset-y
        transition="slide-y-transition"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="toolbar-items mr-4"
          >
            <template #prepend>
              <v-icon size="x-small">
                mdi-account
              </v-icon>
            </template>
            <span
              v-if="windowWidth > 480" 
              style="color:gray; text-transform: none; font-size: 12px" 
              class="font-weight-regular ml-1"
            >
              {{ userCaption }}
            </span>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item @click="profile">
              <template #prepend>
                <v-avatar icon="irz-account-circle" />
              </template>
              <v-list-item-title class="font-weight-light">
                {{ $t('Core.Toolbar.editUser') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!$store.getters.isSuperuser"
              @click="settings"
            >
              <template #prepend>
                <v-avatar icon="irz-cog-outline" />
              </template>
              <v-list-item-title class="font-weight-light">
                {{ $t('Core.Toolbar.settings') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="onClickLang">
              <template #prepend>
                <v-avatar icon="irz-translate" />
              </template>
              <v-list-item-title class="font-weight-light">
                {{ $t('Core.Toolbar.language') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="switchDarkTheme(!nightMode)">
              <template #prepend>
                <v-avatar :icon="nightMode ? 'irz-weather-night' : 'irz-weather-sunny'" />
              </template>
              <v-list-item-title class="font-weight-light">
                {{ $t('Core.Toolbar.theme') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="logout">
              <template #prepend>
                <v-avatar icon="irz-exit-to-app" />
              </template>
              <v-list-item-title class="font-weight-light">
                {{ $t('Core.Drawer.logout') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    data: () => ({
	    nightMode: false,
        title: null,
        responsive: false,
        responsiveInput: false,
        windowWidth: 900,
        hideToolbar: false,
        lastScrollPosition: 0,
        backRoute: undefined,
    }),

    computed: {
        ...mapState(["user"]),

        userCaption() {
            let n = this.user.full_name ? this.user.full_name : this.user.name
            let d = this.user.domain_name
            return n + (d ? " / " + d : "")
        },

    },

    watch: {
        $route(val) {
            this.title = val.meta.alias;
            this.backRoute = val.meta.backRoute;
            this.hideToolbar = false;
            this.lastScrollPosition = 0;
            this.curScrollPosition = 0;
        }
    },
    
    beforeMount() {
      this.$store.dispatch('getCustomCommandTypes');
    },

    mounted() {
        // this.$store.dispatch('getZeros');
        // this.$store.dispatch('loadServersList');
        this.nightMode = this.$store.state.isDarkTheme;
        this.onResponsiveInverted();
	
        window.addEventListener("resize", this.onResponsiveInverted);
//        window.addEventListener("scroll", this.onScroll);
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.onResponsiveInverted);
//        window.removeEventListener("scroll", this.onScroll);
    },

    methods: {
        ...mapMutations(["setDrawer", "toggleDrawer"]),
        notification_rules() {
            this.$router.push('/notifications/rules')
        },
        profile() {
            this.$router.push('/profile')
        },
        settings() {
            this.$router.push('/settings')
        },
        logout() {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push('/login')
                })
        },
        onClickBtn() {
            this.setDrawer(!this.$store.state.drawer);
        },

        onClickLang() {
            this.$i18n.locale = this.$i18n.locale === 'ru' ? 'en' : 'ru'
            this.$cookies.setJSON('locale', this.$i18n.locale);
        },

        onResponsiveInverted() {
            if (window.innerWidth < window.BREAK_POINT) {
                this.responsive = true;
                this.responsiveInput = false;
            } else {
                this.responsive = false;
                this.responsiveInput = true;
            }
            this.windowWidth = window.innerWidth;
        },

        onScroll() {
            const curScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if (curScrollPosition < 0 || Math.abs(curScrollPosition - this.lastScrollPosition) < 50) return;

            this.hideToolbar = curScrollPosition > this.lastScrollPosition;
            this.lastScrollPosition = curScrollPosition;
        },

        switchDarkTheme(e) {
            this.nightMode = e;
            this.$store.commit('switchDarkTheme', e)
        },

        exit() {
            this.$router.backOrGo({name: this.backRoute});
/*            if (this.backRoute === 'back' || this.$router.options.history.state.back) {
                this.$router.back();
            } else if (this.backRoute) {
                this.$router.push({name: this.backRoute});
            }
*/      },

    }
};
</script>

<style scoped>
#core-toolbar {
    background: transparent;
}

#core-toolbar a {
    text-decoration: none;
}

.v-alert {
    padding: 8px;
}
</style>
